import styled from 'styled-components'

import { CommonFlexRow } from '../../../../components/jetonStyled'
import { devices } from '../../../../constants'

export const FilterWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`

export const FilterButtons = styled.div`
  ${CommonFlexRow(2)}
  justify-content: center;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;

  .top-row {
    ${CommonFlexRow()}
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1.8rem;
      font-weight: 400;
      color: #131e3d;
    }
  }

  .multiple-inputs {
    @media ${devices.sm} {
      display: flex;
    }
  }

  .input-wrapper {
    margin-bottom: 2rem;
    margin-right: 1.5rem;
    &:last-of-type {
      margin-right: 0;
    }

    & > div {
      width: 100%;
      flex-grow: 1;
      background: #ffffff;
      border: 1px solid rgba(133, 160, 173, 0.3);
      padding: 0;
    }

    label {
      color: #85a0ad;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      padding-left: 0;
      text-transform: none;
    }

    input {
      height: 3.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      padding-left: 1rem;
      padding-right: 1rem;

      &::-webkit-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &::placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'] {
    & > div {
      height: 3.5rem;
      padding: 0 1rem;

      .icon {
        margin-right: 0;
      }

      input {
        cursor: pointer;
      }
    }
  }

  [data-test='number-input-id'] {
  }
  [data-test='text-input-foreignTransactionId'] {
  }
  [data-test='text-input-reference'] {
  }
  [data-test='text-input-customerEmail'],
  [data-test='text-input-description'] {
  }
  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
  }
  [data-test='select-input-statuses'] {
    & > div {
      padding-left: 1rem;
      padding-right: 0;
    }
  }
  [data-test='select-input-currency'] {
    & > div {
      padding-left: 1rem;

      padding-right: 0;
    }
  }
  [data-test='select-input-paymentMethods'] {
    & > div {
      padding-left: 1rem;

      padding-right: 0;
    }
  }
`
export const TestUnused = styled.div``

// noinspection DuplicatedCode
export const FilterSelectStyles = {
  container: provided => ({
    ...provided,
    width: '100%',
    fontFamily: 'inherit',
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    background: '#131E3D'
  }),
  control: provided => ({
    ...provided,
    cursor: 'pointer',
    color: '#ffffff',
    background: '#131E3D',
    borderWidth: '1px',
    borderStyle: 'none',
    minHeight: '3.5rem',
    // paddingLeft: '1rem',
    padding: '0',
    transition: 'none',
    boxShadow: 'none',
    outline: 'none'
  }),
  multiValue: provided => ({
    ...provided,
    background: '#4b4b4b',
    color: '#ffffff'
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'inherit'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: provided => ({
    ...provided,
    color: '#ffffff',
    padding: '0 8px'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '0 8px 0 0'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 8px 0 0',
    color: '#ffffff'
  }),
  input: provided => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  placeholder: provided => ({
    ...provided,
    color: '#ffffff',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '120%'
  }),
  menu: provided => ({
    ...provided,
    background: '#ffffff',
    border: 'none !important',
    margin: '1rem 0 0 0',
    padding: '1rem 0',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: provided => ({
    ...provided,
    color: '#131E3D',
    fontSize: '1.4rem',
    lineHeight: '120%',
    cursor: 'pointer',
    textAlign: 'left',
    background: '#ffffff',
    // marginTop: '5px',
    padding: '1rem 2rem',
    ':hover': {
      background: '#e7ebee'
    }
  })
}

export const CheckBoxGroupContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e1e5ea;
  border-radius: 10px;
  padding: 2rem 3rem;
`
