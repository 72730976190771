import { useHistory } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import { CommonPageTitle } from './jetonStyled'
import { AccessDeniedContainer } from './styled/accessDenied.styles'

function AccessDenied() {
  const history = useHistory()

  return (
    <AccessDeniedContainer>
      <div className='text-container'>
        <CommonPageTitle centered>{tr('FRONTEND.ACCESS_DENIED.TITLE', 'Access Denied')}</CommonPageTitle>
        <h2 className='headline'>
          {tr(
            'FRONTEND.ACCESS_DENIED.DESCRIPTION',
            'Sorry but it seems that you cannot access this area because your account does not have the right to view this page.'
          )}
        </h2>
        <Button
          type='button'
          color='primary'
          className='button-back'
          text={tr('FRONTEND.ACCESS_DENIED.BACK_BUTTON', 'Back to overview')}
          onClick={() => history.push(paths.dashboard())}
          data-test='button-back'
        />
      </div>
    </AccessDeniedContainer>
  )
}

export default AccessDenied
