import styled from 'styled-components'

import { CommonFlexColumn } from '../jetonStyled'

export const AccessDeniedContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  max-height: 23rem;
  max-width: 35rem;

  padding: 2rem;

  margin: 6rem auto 0 auto;

  ${CommonFlexColumn()}
  justify-content: center;
  align-items: center;

  @media (min-width: 500px) {
    max-width: 50rem;
  }

  .text-container {
    margin: 0 auto;
    text-align: center;

    padding: 0 2rem;

    .headline {
      font-weight: 1.4rem;
      line-height: 2.2rem;

      font-weight: 400;
    }

    .button-back {
      max-width: 40rem;
      margin-top: 2rem;

      font-size: 1.8rem;
    }
  }
`
