import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { AppDispatch, RootState } from '../store'

export * from './users'
export * from './merchants'
export * from './useHideHeaders'
export * from './useDeposit'
export * from './useDownloadFile'
export * from './useOnboardingProgress'
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
