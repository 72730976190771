import styled from 'styled-components'

import HamburgerMenuIcon from '../../images/icons/hamburger-menu.svg'
import { CommonFlexRow } from '../jetonStyled'

export const DropDownMenuWrapper = styled.div`
  position: relative;
  &.hamburger {
    height: 6rem;
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
  }
`

export const InviteAndEarnMenuContainer = styled.div`
  position: absolute;
  right: -6rem;
  width: 20rem;
  z-index: 1001;

  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  padding: 1.5rem;
  .menu-item {
    border-radius: 4px;
    height: 4.5rem;

    div {
      ${CommonFlexRow(1)}
    }

    color: #8a99a8;
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;

    justify-content: flex-start;

    :hover {
      color: #131e3d;
      font-weight: 600;
    }

    &.selector {
      padding: 0 1rem;
    }
  }
`

export const DropDownMenuContainer = styled.div`
  position: absolute;
  left: -15rem;
  top: 9rem;
  width: 20rem;
  z-index: 1001;

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  padding: 1.5rem;

  &.settings {
    left: -5rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 3rem;
    padding: 2rem 2.5rem;
  }

  .menu-item {
    color: #8a99a8;
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 400;

    justify-content: flex-start;

    text-decoration: none;

    &.logout {
      color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      background: #06f;

      text-align: center;
      padding: 1rem;
    }
  }
`

export const DropDownMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 1.6rem 2.5rem;

  cursor: pointer;

  .menu-icon {
    width: 2.2rem;
    height: 2.2rem;
    //prettier-ignore
    background: url("${HamburgerMenuIcon}") center center no-repeat;
  }

  &.arrow-down {
    transition: 'transform 150ms ease';

    &.active {
      transform: rotate(-180deg);
    }
  }
`
