import { useContext, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import cn from 'classnames'

import {
  isMerchantProspect,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantQuery,
  useGetReferralsQuery,
  useUpdateCurrencyPreferenceMutation
} from 'mmfintech-backend-api'
import { copyTextToClipboard, formatMoney, GlobalContext, isValidArray, tr, useWindowSize } from 'mmfintech-commons'
import { Button, ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import AccessDenied from '../../components/AccessDenied'
import CurrencyIcon from '../../components/CurrencyIcon'
import { CommonPageTitle, RowsMainSection, RowsTopSection, RowsTwoSectionContainer } from '../../components/jetonStyled'
import { breakpoints } from '../../constants'
import QualifyModal from './QualifyModal'
import ReferralAmountPane from './ReferralAmountPane'
import ReferralStatistics from './ReferralStatistics'
import ReferralTierPane from './ReferralTierPane'
import SendEmailModal from './SendEmailModal'
import {
  BonusCurrencyOption,
  BonusCurrencyWrapper,
  ReferralBottomSection,
  ReferralMainSection,
  SocialMediaShare
} from './styled/referralProgram.styled'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

import ArrowIcon from '../../images/icons/arrow-right-small-black.svg?react'
import CopyIcon from '../../images/icons/referral-copy-to-clipboard.svg?react'
import FacebookIcon from '../../images/icons/referral-facebook.svg?react'
import LinkedInIcon from '../../images/icons/referral-linkedin.svg?react'
import MailIcon from '../../images/icons/referral-mail.svg?react'
import TelegramIcon from '../../images/icons/referral-telegram.svg?react'
import WhatsAppIcon from '../../images/icons/referral-whatsapp.svg?react'
import ShareIcon from '../../images/icons/share-icon.svg?react'

const formattedValue = (currentValue: number, nextValue: number, currency: string) => {
  if (currentValue !== undefined && nextValue !== undefined) {
    return `${formatMoney(currentValue, currency, 0)} - ${formatMoney(nextValue, currency, 0)}`
  }
  if (nextValue === undefined) {
    return `> ${formatMoney(currentValue, currency, 0)}`
  }
  return 0
}

function ReferralProgram() {
  const { modalShow, modalHide } = useContext(GlobalContext)
  const customerRole = useAppSelector(selectCurrentUserRole)
  const { data: merchant } = useGetMerchantQuery()
  const {
    data: merchantReferrals,
    isFetching: merchantReferralsFetching,
    error: merchantReferralsError
  } = useGetReferralsQuery()
  const [updatePreference, { error: updatePreferenceError }] = useUpdateCurrencyPreferenceMutation()

  const isProspect = isMerchantProspect(merchant)
  const { capabilities } = merchant || {}
  const { allowReferrals } = capabilities || {}

  const {
    inviteLink,
    totalRewards,
    enabled,
    tiers,
    referralTier,
    lastTierUpdate,
    transactionVolume,
    currencyPreference
  } = merchantReferrals || {}
  const useThisCurrency = currencyPreference ? currencyPreference.split('.')[0] : ''

  const [width] = useWindowSize()

  const handleSelectBonusCurrency = (currency: string) => {
    if (!isProspect && currency !== currencyPreference) {
      updatePreference({ currency })
    }
  }

  const handleCopyClick = (inviteLink: string) => {
    if (enabled) {
      copyTextToClipboard(inviteLink, () => {
        toast.remove()
        toast.success('Link copied to clipboard')
      })
      return false
    }
  }

  const handleSendViaEmail = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto' },
      content: <SendEmailModal />
    })
  }

  const handleClickHowToQualify = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto' },
      content: <QualifyModal modalHide={modalHide} />
    })
  }

  const handleOpenShareModal = () => {
    if (enabled) {
      modalShow({
        options: { closeOnClickOutside: true, size: 'auto' },
        content: (
          <SocialMediaIcons handleSendViaEmail={handleSendViaEmail} inviteLink={inviteLink} onClose={modalHide} />
        )
      })
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    // eslint-disable-next-line
  }, [])

  return merchantReferralsFetching ? (
    <Preloader />
  ) : customerRole === CustomerRoleEnum.STAFF || !allowReferrals ? (
    <AccessDenied />
  ) : (
    <RowsTwoSectionContainer size='xl'>
      <RowsTopSection>
        <ReferralMainSection>
          <ReferralTierPane tiers={tiers} referralTier={referralTier} lastTierUpdate={lastTierUpdate} />
          <div className='mid-section'>
            <Button
              type='button'
              className='qualify-button'
              onClick={handleClickHowToQualify}
              data-test='qualify-button'
              text={tr('FRONTEND.REFERRAL.PROGRAM.BANNER_BUTTON', 'How to qualify')}
              icon={<ArrowIcon />}
              iconPosition='right'
            />
            {width >= breakpoints.xl && (
              <CommonPageTitle centered data-test='referral-title'>
                {tr('FRONTEND.REFERRAL.PROGRAM.TITLE', 'Referral Program')}
              </CommonPageTitle>
            )}
            <div className='key-button' data-test='invite-link-copy' onClick={() => handleCopyClick(inviteLink)}>
              <span>Copy invite link</span>``
              <CopyIcon />
            </div>
            {width < breakpoints.xl && (
              <div>
                <ShareIcon onClick={handleOpenShareModal} />
              </div>
            )}
          </div>
          <ErrorDisplay error={merchantReferralsError} />
        </ReferralMainSection>
      </RowsTopSection>
      <RowsMainSection>
        <ReferralBottomSection>
          <div className='left-column'>
            {enabled && (
              <>
                <ReferralAmountPane
                  amount={transactionVolume}
                  currency='EUR'
                  title={tr(
                    'FRONTEND.REFERRAL.PROGRAM.TRANSACTIONAL_VOLUME.TITLE',
                    'Transactional volume from referrals'
                  )}
                />
                <ReferralAmountPane
                  amount={totalRewards}
                  currency={useThisCurrency}
                  title={tr('FRONTEND.REFERRAL.PROGRAM.COMMISSIONS.TITLE', 'Total commission earned')}
                />
                <BonusCurrencyWrapper>
                  <div className='section-heading'>Choose Bonus Currency</div>
                  <div className='inner'>
                    <BonusCurrencyOption
                      onClick={() => handleSelectBonusCurrency('USDT.TRC20')}
                      className={cn({ selected: currencyPreference === 'USDT.TRC20' })}
                      data-test='bonus-currency-usdt'>
                      <div className='main'>
                        <CurrencyIcon width='26px' height='26px' currency='USDT' />
                        <div className='cont'>
                          <span>Crypto</span>
                          <span className='currency'>USDT</span>
                        </div>
                      </div>
                      <div className='radio' />
                    </BonusCurrencyOption>
                    <BonusCurrencyOption
                      onClick={() => handleSelectBonusCurrency('EUR')}
                      className={cn({ selected: currencyPreference === 'EUR', disabled: isProspect })}
                      data-test='bonus-currency-eur'>
                      <div className='main'>
                        <CurrencyIcon width='26px' height='26px' currency='EUR' />
                        <div className='cont'>
                          <span>Fiat</span>
                          <span className='currency'>EUR</span>
                        </div>
                      </div>
                      <div className='radio' />
                    </BonusCurrencyOption>
                  </div>
                </BonusCurrencyWrapper>
              </>
            )}
            <ErrorDisplay error={updatePreferenceError} />
          </div>
          <ReferralStatistics currencyCode={useThisCurrency} />
          {enabled && width >= breakpoints.xl && (
            <SocialMediaIcons handleSendViaEmail={handleSendViaEmail} inviteLink={inviteLink} />
          )}
          <Tooltip tiers={tiers} />
        </ReferralBottomSection>
      </RowsMainSection>
    </RowsTwoSectionContainer>
  )
}

export default ReferralProgram

const Tooltip = ({ tiers }) => (
  <ReactTooltip effect='solid' id='tooltip' place='bottom' className='tooltip-wrapper'>
    <h3>{tr('FRONTEND.REFERRAL.PROGRAM.TOOLTIP.TITLE', 'Commission Rates:')}</h3>
    <div className='tiers-container'>
      {isValidArray(tiers) &&
        tiers.map((tier, index, arr) => {
          const { qualifier, attributeName, value } = tier
          return (
            <div className='tier' key={attributeName}>
              <div className='tier-number'>Tier {attributeName.match(/\d/)[0]}</div>
              <div className='qualifier'>{qualifier}%</div>
              <div className='volume'>{formattedValue(value, arr[index + 1]?.value, 'EUR')} Volume/Monthly</div>
            </div>
          )
        })}
    </div>
  </ReactTooltip>
)

const SocialMediaIcons = ({ handleSendViaEmail, inviteLink, onClose = null }) => (
  <SocialMediaShare>
    <div className='section-heading'>{tr('FRONTEND.REFERRAL.PROGRAM.SHARE.TITLE', 'Share via')}</div>
    <div className='inner'>
      <a target='_blank' rel='noreferrer' href={`https://wa.me/?text=${inviteLink}`} data-test='whatsapp-share'>
        <WhatsAppIcon className='whatsapp-icon' />
      </a>
      <a
        data-test='telegram-share'
        href={`https://telegram.me/share/url?url=${inviteLink}&text=Use my referral link and sign up to Jetonbank`}
        target='_blank'
        rel='noreferrer'>
        <TelegramIcon className='telegram' />
      </a>
      <a
        href={`https://www.linkedin.com/feed/?shareActive&mini=true&text=${inviteLink}`}
        target='_blank'
        rel='noreferrer'
        data-test='linkedin-share'>
        <LinkedInIcon />
      </a>
      <a
        href={`https://facebook.com/sharer/sharer.php?u=${inviteLink}`}
        target='_blank'
        rel='noreferrer'
        data-test='facebook-share'>
        <FacebookIcon />
      </a>
      <Link to='#' data-test='mail-share' onClick={handleSendViaEmail}>
        <MailIcon />
      </Link>
    </div>
    {typeof onClose === 'function' && (
      <Button className='close-button' text={tr('FRONTEND.REFERRAL.QUALIFY.CLOSE_BUTTON', 'Close')} onClick={onClose} />
    )}
  </SocialMediaShare>
)
