import { AccountOverviewList } from '../account/AccountOverviewList'
import SpendingLimitBarUser from '../requests/SpendingLimitBarUser'
import TransactionsSection from './elements/TransactionsSection'
import { StaffDashboardWrapper } from './styled/dashboard.styled'

export const StaffDashboard = () => (
  <StaffDashboardWrapper>
    <div className='dashboard-nav'>
      <SpendingLimitBarUser />
    </div>
    <AccountOverviewList />
    <TransactionsSection />
  </StaffDashboardWrapper>
)
