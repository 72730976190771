import cn from 'classnames'
import { isValidString } from 'mmfintech-commons'
import styled from 'styled-components'

import LogoImage from '../images/logo.svg'
import settings from '../settings'

export function JetonLogo({ autoWidth = false, url = '' }) {
  return (
    <LogoWrapper className={cn({ 'auto-width': autoWidth })}>
      <a href={isValidString(url) ? url : settings.landingPageUrl}>
        <img src={LogoImage} alt='Logo' />
      </a>
    </LogoWrapper>
  )
}

export default JetonLogo

export const LogoWrapper = styled.div`
  width: 92vw;
  max-width: 480px;
  margin-bottom: 3rem;
  text-align: center;
  img {
    max-height: 56px;
  }

  @media (min-width: 640px) {
    width: auto;
  }

  &.auto-width {
    width: auto;
  }
`
