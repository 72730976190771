import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { CookiesProvider, useCookies } from 'react-cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { withTranslation } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'

import styled, { ThemeProvider } from 'styled-components'

import {
  selectCurrentUserStatus,
  useAppInitializerQuery,
  useAppSelector,
  useAuthQuery,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'
import { GlobalContextProvider, isValidObject, OtpContext, useWindowSize } from 'mmfintech-commons'
import { Preloader } from 'mmfintech-portal-commons'

import ContentWrapper from './components/ContentWrapper'
import CustomToaster from './components/CustomToaster'
import Header from './components/Header'
import Modal from './components/ModalDialog'
import { Otp } from './components/Otp'
import { PaymentButtons } from './components/PaymentButtons'
import Sidebar from './components/SideBar/SideBar'
import SidebarRight from './components/SideBar/SidebarRight'
import { ContentContainer } from './components/styled/contentWrapper.styled'
import { breakpoints, devices } from './constants'
import { nonProtectedRoutes, routes } from './routes'
import settings from './settings'
import theme from './theme'
import { PrivateRoute, PublicRoute, statusPaths } from './utils'
import CookieConsent from './views/cookieConsent/CookieConsent'
import SumSubBanner from './views/onboarding/SumSubBanner'
import { LoginStatusEnum } from 'mmfintech-commons-types'

import ErrorIcon from './images/icons/error.svg?react'

const Error404 = React.lazy(() => import('./views/static/Error404'))

function AppInner() {
  useAppInitializerQuery(settings.languages)
  const { isLoading: isFetchingAuth } = useAuthQuery()
  const [cookies, setCookie] = useCookies(['cookie.consent'])

  const [cookieConsent, setCookieConsent] = useState(null)

  const [width] = useWindowSize()
  const userStatus = useAppSelector(selectCurrentUserStatus)
  const queryChallenge = useAppSelector(state => state.challenge)

  const dispatch = useDispatch()

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [sidebarRightContent, setSidebarRightContent] = useState(null)
  const [sidebarRightOptions, setSidebarRightOptions] = useState(null)
  const [sidebarRightVisible, setSidebarRightVisible] = useState(false)
  const [sidebarInnerRightContent, setSidebarInnerRightContent] = useState(null)
  const [sidebarInnerRightOptions, setSidebarInnerRightOptions] = useState(null)
  const [sidebarInnerRightVisible, setSidebarInnerRightVisible] = useState(false)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)
  const [otpOnError, setOtpOnError] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const sidebarRightHide = () => {
    setSidebarRightVisible(false)
    setSidebarRightContent(null)
    setSidebarRightOptions(null)
  }

  const sidebarRightShow = ({ options, content }) => {
    setSidebarRightContent(content)
    setSidebarRightOptions(options)
    setSidebarRightVisible(true)
  }

  const sidebarInnerRightHide = () => {
    setSidebarInnerRightVisible(false)
    setSidebarInnerRightContent(null)
    setSidebarInnerRightOptions(null)
  }

  const sidebarInnerRightShow = ({ options, content }) => {
    setSidebarInnerRightContent(content)
    setSidebarInnerRightOptions(options)
    setSidebarInnerRightVisible(true)
  }

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const globalContext = {
    modalHide,
    modalShow,
    sidebarRightHide,
    sidebarRightShow,
    sidebarInnerRightHide,
    sidebarInnerRightShow
  }

  useEffect(() => {
    isValidObject(queryChallenge) && queryChallenge?.hasOwnProperty('challengeId')
      ? modalShow({
          options: {
            size: 'medium',
            transparent: true,
            closeOnClickOutside: false,
            closeOnEscape: false
          },
          content: <Otp />
        })
      : modalHide()
  }, [queryChallenge, dispatch])

  if (isFetchingAuth) return <Preloader />

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider
            value={{
              otpOnSuccess,
              setOtpOnSuccess,
              otpOnError,
              setOtpOnError
            }}>
            <SkeletonTheme baseColor='#e0e0e0' highlightColor='#ccc'>
              <AppContainer>
                {isLoggedIn() && (
                  <div className='header'>
                    <Header />
                  </div>
                )}
                <div className='mainContent'>
                  {isLoggedIn() && <Sidebar />}
                  <SidebarRight
                    content={sidebarRightContent}
                    options={sidebarRightOptions}
                    visible={sidebarRightVisible}
                    onClose={sidebarRightHide}
                  />
                  <ContentWrapper
                    sideBarInner={{
                      content: sidebarInnerRightContent,
                      options: sidebarInnerRightOptions,
                      visible: sidebarInnerRightVisible,
                      onClose: sidebarInnerRightHide
                    }}>
                    <ContentContainer>
                      <Switch>
                        {nonProtectedRoutes.map(({ path, Component }, index) => (
                          <PublicRoute key={index} path={path} exact component={Component} />
                        ))}
                        {routes.map(({ path, PrivateComponent, redirect }, index) => (
                          <PrivateRoute
                            key={index}
                            path={path}
                            exact
                            component={PrivateComponent}
                            redirect={redirect}
                            invalidSessionRedirect={statusPaths(userStatus)}
                          />
                        ))}
                        <Route path='*' component={Error404} />
                      </Switch>

                      {isValidObject(cookieConsent) ? null : <CookieConsent setCookie={setCookie} />}
                      <Modal content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />
                      {width >= breakpoints.xl && isLoggedIn() && <SumSubBanner />}
                    </ContentContainer>
                  </ContentWrapper>
                  {width <= breakpoints.xl && isLoggedIn() && <PaymentButtons />}
                </div>
              </AppContainer>
              <CustomToaster />
            </SkeletonTheme>
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>An unexpected error occurred</p>
        <TryAgainButton type='button' onClick={resetErrorBoundary}>
          Go back to the home page
        </TryAgainButton>
      </Alert>
    </AlertWrapper>
  )
}

const ThisApp = withTranslation()(AppInner)

function App() {
  const [sendError] = useLazyErrorLoggingQuery()
  return (
    <Suspense
      fallback={
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, { componentStack }) => sendError({ level: 'ERROR', componentStack, message: err.toString() })}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100svh;

  @media ${devices.xl} {
    height: 100vh;
  }

  .mainContent {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    background-color: ${({ theme }) => theme.contentWrapper.backgroundColor};
    /* background-color: #fff; */

    @media (max-width: ${breakpoints.xl}px) {
      flex-direction: column;
    }
  }
  .start-button {
    background: #699cfc;
  }
  .start-button:hover {
    background: #508bfb;
  }
  ${'' /* react-datepicker styling */}
  .react-datepicker {
    background-color: ${({ theme }) => theme.datepicker.container.backgroundColor};
    border-color: ${({ theme }) => theme.datepicker.container.borderColor};
    border-radius: ${({ theme }) => theme.datepicker.container.borderRadius};
    box-shadow: ${({ theme }) => theme.datepicker.container.boxShadow};
    font-family: inherit;
    font-size: ${({ theme }) => theme.datepicker.container.fontSize};
    padding: ${({ theme }) => theme.datepicker.container.padding};
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.datepicker.header.backgroundColor};
    border-bottom-color: ${({ theme }) => theme.datepicker.header.borderColor};
    border-top-left-radius: ${({ theme }) => theme.datepicker.container.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.datepicker.container.borderRadius};
    padding: ${({ theme }) => theme.datepicker.header.padding};
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${({ theme }) => theme.datepicker.header.textColor};
    font-size: ${({ theme }) => theme.datepicker.header.fontSize};
    font-weight: ${({ theme }) => theme.datepicker.header.fontWeight};
    line-height: ${({ theme }) => theme.datepicker.header.lineHeight};
  }
  .react-datepicker__day-names {
    margin-top: ${({ theme }) => theme.datepicker.dayName.marginTop};
    margin-bottom: ${({ theme }) => theme.datepicker.dayName.marginBottom};
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.datepicker.dayName.textColor};
    font-size: ${({ theme }) => theme.datepicker.dayName.fontSize};
    font-weight: ${({ theme }) => theme.datepicker.dayName.fontWeight};
    line-height: ${({ theme }) => theme.datepicker.dayName.lineHeight};
    margin: ${({ theme }) => theme.datepicker.cells.margin};
    width: ${({ theme }) => theme.datepicker.cells.width};
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker__navigation--next {
    top: ${({ theme }) => theme.datepicker.header.arrowTop};
    right: ${({ theme }) => theme.datepicker.header.arrowLeftRight};
  }
  .react-datepicker__navigation--previous {
    top: ${({ theme }) => theme.datepicker.header.arrowTop};
    left: ${({ theme }) => theme.datepicker.header.arrowLeftRight};
  }
  .react-datepicker__day {
    border: ${({ theme }) => theme.datepicker.cells.border};
    border-radius: ${({ theme }) => theme.datepicker.cells.borderRadius};
    font-size: ${({ theme }) => theme.datepicker.cells.fontSize};
    font-weight: ${({ theme }) => theme.datepicker.cells.fontWeight};
    line-height: ${({ theme }) => theme.datepicker.cells.height};
    margin: ${({ theme }) => theme.datepicker.cells.margin};
    width: ${({ theme }) => theme.datepicker.cells.width};
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: ${({ theme }) => theme.datepicker.container.borderColor};
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: ${({ theme }) => theme.datepicker.header.backgroundColor};
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.datepicker.container.borderColor};
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.datepicker.header.backgroundColor};
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.datepicker.header.textColor};
    border-top-width: ${({ theme }) => theme.datepicker.header.arrowSize};
    border-right-width: ${({ theme }) => theme.datepicker.header.arrowSize};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: ${({ theme }) => theme.datepicker.cells.selectedBackground};
    border: ${({ theme }) => theme.datepicker.cells.selectedBorder};
    color: ${({ theme }) => theme.datepicker.cells.selectedColor};
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: ${({ theme }) => theme.datepicker.cells.hoverBackground};
  }
  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.datepicker.cells.outsideColor};
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: ${({ theme }) => theme.datepicker.cells.hoverTextColor};
    background-color: ${({ theme }) => theme.datepicker.cells.hoverBackground};
    border: ${({ theme }) => theme.datepicker.cells.hoverBorder};
  }
`

const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: #ffffff;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`
