import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  .left-side {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4rem;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  @media (max-width: 767px) {
    .left-side,
    .right-side {
      gap: 2rem;
    }
  }
`

export const StaffDashboardWrapper = styled.div`
  .dashboard-nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    margin-bottom: 2rem;

    @media (min-width: 700px) {
      flex-flow: row nowrap;
      align-items: center;
    }

    .button {
      height: 46px;
      border-radius: 4px;
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      text-transform: capitalize;
      padding: 0 3rem;

      @media (min-width: 700px) {
        max-width: 14rem;
      }
    }
  }
`

export const DashboardContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;

padding: 0 2rem;
`
