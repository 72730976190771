import { useContext, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'

import {
  isOwnerOrAdministrator,
  resetEmailVerification,
  selectCurrentUserRole,
  selectInitialEmailVerification,
  useAppSelector,
  useGetAggregatedBalanceQuery,
  useGetSumSubStatusQuery
} from 'mmfintech-backend-api'
import { GlobalContext, tr, useWindowSize } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { AccountsPanel } from '../../components/AccountsPanel'
import { LastAction } from '../../components/LastAction'
import { TotalSpendRow } from '../../components/TotalSpendRow'
import {
  ColumnLeftSectionContainer,
  ColumnMainSectionContainer,
  ColumnTwoSectionContainer,
  CommonPageTitle
} from '../../components/jetonStyled'
import { breakpoints } from '../../constants'
import { StaffDashboard } from './StaffDashboard'
import AccountSection from './elements/AccountSection'
import GraphSection from './elements/GraphSection'
import { OnboardingBanner } from './elements/OnboardingBanner'
import TransactionsSection from './elements/TransactionsSection'
import WelcomeModal from './elements/WelcomeModal'
import { DashboardContainer, DashboardWrapper } from './styled/dashboard.styled'

function Dashboard() {
  const { modalShow } = useContext(GlobalContext)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)
  const customerRole = useAppSelector(selectCurrentUserRole)
  const {
    data: aggregatedBalance,
    isFetching: aggregatedBalanceFetching,
    error: aggregatedBalanceError
  } = useGetAggregatedBalanceQuery({
    dateFrom: '',
    dateTo: ''
  })
  useGetSumSubStatusQuery()
  // const { initialEmailVerification } = useUserState()
  // const { invoice, invoicePreview } = useInvoicesState()

  const dispatch = useDispatch()
  const [width] = useWindowSize()

  // useEffect(() => {
  //   if (invoice && invoicePreview) {
  //     modalShow({
  //       options: { size: 'extra-large' },
  //       content: <InvoicePreview status={invoice.status} content={invoicePreview} onClose={modalHide} />
  //     })
  //     dispatch(actions.invoice.resetPreview())
  //   }
  //   // eslint-disable-next-line
  // }, [invoice, invoicePreview])

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
      dispatch(resetEmailVerification())
    }
    // eslint-disable-next-line
  }, [initialEmailVerification])

  const transactionFilter = {
    page: 0,
    size: 5,
    sort: 'reqTime-desc,id-desc'
  }
  return (
    <DashboardContainer>
      {width > breakpoints.xl && <OnboardingBanner />}
      <ColumnTwoSectionContainer size='xl' additionalStyles={{ gap: '5rem', padding: '2rem 0' }}>
        {isOwnerOrAdministrator(customerRole) ? (
          <>
            <ColumnLeftSectionContainer>
              <DashboardWrapper>
                <ErrorDisplay error={aggregatedBalanceError} />

                <div className='left-side'>
                  {aggregatedBalanceFetching ? (
                    <Skeleton height={40} />
                  ) : (
                    <AccountSection aggregatedBalance={aggregatedBalance} />
                  )}
                  {width > breakpoints.xl && (
                    <>
                      <AccountsPanel />
                      <LastAction />
                    </>
                  )}
                </div>
              </DashboardWrapper>
            </ColumnLeftSectionContainer>
            <ColumnMainSectionContainer>
              <DashboardWrapper>
                <div className='right-side'>
                  <TotalSpendRow />
                  <GraphSection />
                  {width > breakpoints.xl && <TransactionsSection filter={transactionFilter} />}
                </div>
              </DashboardWrapper>
            </ColumnMainSectionContainer>
          </>
        ) : (
          <>
            <CommonPageTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</CommonPageTitle>
            <StaffDashboard />
          </>
        )}
      </ColumnTwoSectionContainer>
    </DashboardContainer>
  )
}

export default Dashboard
