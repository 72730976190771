import styled from 'styled-components'

export const VerificationContainer = styled.div`
  position: relative;

  padding: 3rem 2rem;

  @media (min-width: 360px) {
    padding: 3rem 5rem 4rem;
  }

  width: 100%;
  max-width: 50rem;

  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  justify-content: center;
  margin: auto;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  .err-msg-wrapper {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .title {
    color: #131e3d;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.9rem;
    text-align: center;
  }

  .subtitle {
    color: #131e3d;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    text-align: center;

    span {
      display: block;
      font-weight: 700;
      overflow-wrap: anywhere;
    }
  }
  .smart-id-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .input {
    color: ${({ theme }) => theme.contentWrapper.textColor};
    font-family: inherit;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: 1.2rem;

    text-align: center;

    width: 20rem;
    margin: 0 auto;
    padding-bottom: 1rem;

    border: none;
    border-bottom: 1px solid #d9d9d9;
    background-color: #ffffff;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }
    &:-ms-input-placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }
    &::placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }

    &.wrong {
      border-color: ${({ theme }) => theme.errorDisplay.borderColor};
    }
  }
`

export const Input = styled.input``

export const ResendMessage = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  color: #131e3d;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: center;

  span {
    border-radius: 4rem;
    border: 1px solid #bfc8d9;

    color: rgba(47, 128, 237, 1);
    font-size: 1.2rem;

    padding: 1rem 2rem;

    cursor: pointer;

    &.disabled {
      color: #545454;
      cursor: default;
      text-decoration: none;
    }
  }
`

export const Countdown = styled.div<{ isExpired: boolean }>`
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  right: 2rem;

  color: ${p => (p.isExpired ? '#ee2b2b' : '#939ea7')};
  font-family: inherit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
`
