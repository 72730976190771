import styled from 'styled-components'

import { CommonPageTitle } from '../../../components/jetonStyled'
import { devices } from '../../../constants'

export const InvoicesWrapper = styled.div`
  grid-area: main-content;
  padding: 6rem 2.5rem 4.5rem;
`

export const InvoicesHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const InvoicesTitle = styled(CommonPageTitle)`
  margin-bottom: 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 2rem;

  margin-top: 2rem;
  .button {
    font-weight: 400;
    color: #85a0ad;
  }

  .left-side {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;

    @media (min-width: 600px) {
      flex-flow: row nowrap;
    }
  }

  @media (min-width: 600px) {
    display: inline-flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  @media (min-width: 800px) {
    width: auto;
    margin-top: 2rem;

    & > .button {
      max-width: initial;
      //min-width: 18rem;
      padding-left: 2rem;
    }

    & > .filter-wrapper {
      padding: 0;
    }
  }
`
export const InvoicesInner = styled.div`
  display: grid;
  align-items: center;
  width: 100%;

  @media ${devices.xs} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    button {
      max-width: 15rem;
    }
  }
  @media ${devices.xl} {
    grid-template-columns: auto 1fr auto;
  }

  .right-side {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }
`
