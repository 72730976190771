import { isValidString, tr, translateCountryName } from 'mmfintech-commons'

import { CommonPageTitle } from '../../components/jetonStyled'
import { RecipientPreviewData, RecipientPreviewWrapper } from './styled/recipientDetailsPreview.styled'

import { BankAccountOut, ExternalCryptoAddressOut, ExternalWalletAccountOut } from 'mmfintech-commons-types'

import CloseIcon from '../../images/icons/icon-close-transparent.svg?react'

export type RecipientProps = BankAccountOut & ExternalCryptoAddressOut & ExternalWalletAccountOut

export type RecipientDetailsModalProps = {
  recipient: RecipientProps
  onClose: () => void
}

const KEYS_TO_RENDER = [
  'name',
  'country',
  'bankName',
  'bankCode',
  'branchCode',
  'accountNumber',
  'documentId',
  'description',
  'bank',
  'iban',
  'address'
]

function RecipientDetailsModal({ recipient, onClose }: RecipientDetailsModalProps) {
  if (recipient?.country) {
    const countryName = translateCountryName(recipient.country)
    recipient = { ...recipient, country: countryName.includes('METADATA') ? recipient.country : countryName }
  }
  return (
    <RecipientPreviewWrapper data-test='recipient-details'>
      <div className='top-section'>
        <CommonPageTitle normalFontWeight>{tr('FRONTEND.RECIPIENTS.VIEW.TITLE', 'Recipient Details')}</CommonPageTitle>
        <CloseIcon onClick={onClose} />
      </div>
      <div className='list'>
        {KEYS_TO_RENDER.map((key, ind) => {
          if (
            (typeof recipient[key as keyof RecipientProps] === 'number' ||
              typeof recipient[key as keyof RecipientProps] === 'string') &&
            KEYS_TO_RENDER.includes(key)
          ) {
            const value = recipient[key as keyof RecipientProps]
            return (
              <LabelValue
                label={tr(
                  `FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.RECIPIENT_DETAILS.${key.toUpperCase()}`,
                  `${key.toUpperCase()}`
                )}
                value={value}
                key={ind}
                dataTest={`recipient-details-${key}`}
              />
              // <Input
              //   type='static'
              //   data-test={`recipient-details-${key}`}
              //   label={tr(
              //     `FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.RECIPIENT_DETAILS.${key.toUpperCase()}`,
              //     `${key.toUpperCase()}`
              //   )}
              //   value={isValidString(value) ? value : ''}
              //   key={ind}
              // />
            )
          }
          return null
        })}
      </div>
    </RecipientPreviewWrapper>
  )
}

export default RecipientDetailsModal

function LabelValue({ label, value, dataTest }: { label: string; value: any; dataTest: string }) {
  return isValidString(value) ? (
    <RecipientPreviewData>
      <div className='label'>{label}</div>
      <span data-test={dataTest}>{value}</span>
    </RecipientPreviewData>
  ) : null
}
